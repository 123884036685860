import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	ViewMode,
	ViewModeSym,
	ViewerModel,
	ViewerModelSym,
	IFetchApi,
	Fetch,
	FeatureStateSymbol,
	ILogger,
	LoggerSymbol,
	BrowserWindow,
	BrowserWindowSymbol,
	PageFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { IFeatureState } from 'thunderbolt-feature-state'
import { IComponentPropsExtender } from 'feature-components'
import type { WixappsFeatureState, WixappsAppType, WixappsPageConfig } from './types'
import { name } from './symbols'
import { WixappsFetchError, WixappsOldBlogAppPartError, WixappsUnsupportedAppError } from './errors'
import { getWixappslessHtmlUrl } from './utils'

const SUPPORTED_APP_TYPES = new Set<WixappsAppType>(['appbuilder', 'faq', 'menu', 'news'])

const isSupportedAppType = (appType: WixappsAppType) => SUPPORTED_APP_TYPES.has(appType)

const wixappsFactory = (
	featureState: IFeatureState<WixappsFeatureState>,
	appPartsTypes: WixappsPageConfig,
	viewerModel: ViewerModel,
	viewMode: ViewMode,
	fetchApi: IFetchApi,
	logger: ILogger,
	window: BrowserWindow
): IComponentPropsExtender<{ __html: string }> => {
	const {
		site: { metaSiteId },
	} = viewerModel

	const reportWixappsError = (err: Error, appPartId: string) => {
		logger.captureError(err, {
			tags: {
				feature: 'wixapps',
			},
			extra: {
				appPartId,
				appPartType: appPartsTypes[appPartId],
			},
		})
	}

	const getAppPartHtmlFromSsrResponse = (appPartId: string) =>
		window?.document?.getElementById(appPartId)?.innerHTML || ''

	const fetchAppPartHtml = async (appPartId: string) => {
		try {
			const appPartHtmlUrl = getWixappslessHtmlUrl({
				metaSiteId,
				viewMode,
				appPartFilename: appPartId,
			})
			const response = await fetchApi.envFetch(appPartHtmlUrl)
			if (response.status === 200) {
				return response.text()
			}

			throw new WixappsFetchError(
				// non existing files get 403 response code from wixmp
				response.status === 403
					? `AppPart HTML File Not Found`
					: `AppPart HTML Fetch error: ${response.status} ${response.statusText}`
			)
		} catch (err) {
			reportWixappsError(err, appPartId)
			return ''
		}
	}

	const getAppPartHtml = async (appPartId: string) => {
		if (featureState.get()?.appPartsHtml?.[appPartId]) {
			return featureState.get().appPartsHtml![appPartId]
		}

		const appPartHtml = getAppPartHtmlFromSsrResponse(appPartId) || (await fetchAppPartHtml(appPartId))

		featureState.update(({ appPartsHtml = {} } = {}) => {
			appPartsHtml[appPartId] = appPartHtml
			return { appPartsHtml }
		})

		return appPartHtml
	}

	return {
		componentTypes: ['AppPart', 'AppPart2'],
		getExtendedProps: async (appPartId) => {
			const appPartType = appPartsTypes[appPartId]
			if (isSupportedAppType(appPartType)) {
				return {
					__html: await getAppPartHtml(appPartId),
				}
			}

			const unsupportedError =
				appPartType === 'blog' ? new WixappsOldBlogAppPartError() : new WixappsUnsupportedAppError(appPartType)
			reportWixappsError(unsupportedError, appPartId)

			return {
				__html: `<div data-unsupported-app-type="${appPartType}"></div>`,
			}
		},
	}
}

export const Wixapps = withDependencies(
	[
		named(FeatureStateSymbol, name),
		named(PageFeatureConfigSymbol, name),
		ViewerModelSym,
		ViewModeSym,
		Fetch,
		LoggerSymbol,
		BrowserWindowSymbol,
	],
	wixappsFactory
)
