import { ViewMode } from '@wix/thunderbolt-symbols'

export const WIXAPPSLESS_BASE_URL = 'https://wixmp-04822721f0ca4076af19b734.wixmp.com/wixappsless'

export const getWixappslessHtmlUrl = ({
	metaSiteId,
	viewMode,
	appPartFilename,
}: {
	metaSiteId: string
	viewMode: ViewMode
	appPartFilename: string
}) => `${WIXAPPSLESS_BASE_URL}/${metaSiteId}/${viewMode}/${appPartFilename}.html`
