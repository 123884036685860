export class WixappsFetchError extends Error {
	constructor(message: string) {
		super(message)
		this.name = 'WixappsFetchError'
	}
}

export class WixappsOldBlogAppPartError extends Error {
	constructor() {
		super('Unexpected AppPart for Old-Blog')
		this.name = 'WixappsOldBlogAppPartError'
	}
}

export class WixappsUnsupportedAppError extends Error {
	constructor(appPartType: string) {
		super(`Unexpected AppPart Type "${appPartType}"`)
		this.name = 'WixappsUnsupportedAppError'
	}
}
